import axios from 'axios'
import EventBus from '@/libs/AppEventBus'
import JSONbig from 'json-bigint'
import { useAuthStore } from '@/modules/auth/authStore'
import router from '@/routers'
import Crypton from '@/libs/crypton'
import crypto from 'crypto-js'

const api = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-REQUEST-PLATFORM': 'PORTAL'
    }
})

api.interceptors.request.use(function (config) {
    const authStore = useAuthStore()
    const token = authStore.getToken
    config.headers.common['Authorization'] = `Bearer ${token}`
    return config
})

api.interceptors.request.use(function (config) {
    const url = config.baseURL.replace(/\/$/, '') + config.url
    const secret = process.env.VUE_APP_REQUEST_SECRET
    try {
        const hmacDigest = crypto.HmacSHA256(url, secret).toString(crypto.enc.Hex)
        config.headers.common['X-GSignature'] = hmacDigest
        return config
    } catch (error) {
        throw new Error(error)
    }
})

api.interceptors.response.use(
    (res) => {
        if (res.data.payload !== undefined) {
            const decrypt = new Crypton('base64:alhuMnI1dTh4L0E1RChHK0thUGRTZ1ZrWXAzczZ2OXk=').decrypt(res.data.payload)
            res.data = JSONbig.parse(JSON.stringify(decrypt))
        } else {
            res.data = JSONbig.parse(JSON.stringify(res.data))
        }
        return res
    },
    (err) => {
        if (err.response) {
            if (err.response.status === 401) {
                const store = useAuthStore()
                store.logout()
                router.push({ name: 'login' })
            } else if (err.response.status === 403) {
                router.push({ name: 'not-authorized' })
            } else if (err.response.status === 422) {
                return Promise.reject(err.response)
            }
            /**
             * Can see EventBus in AppWrapper(Top Component)
             */
            if (err.response.status === 500) {
                EventBus.emit('show-message', {
                    severity: 'error',
                    summary: '',
                    detail: err.response.data.message
                })
            }
            EventBus.emit('show-toast', {
                severity: 'error',
                summary: '',
                detail: err.response.data.message
            })
        } else if (err.request) {
            EventBus.emit('show-toast', {
                severity: 'error',
                summary: '',
                detail: err.message
            })
        } else {
            EventBus.emit('show-toast', {
                severity: 'error',
                summary: '',
                detail: err.message
            })
        }
    }
)

export default api
